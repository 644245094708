// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	firebase: {
		projectId: "apsc-46801",
		appId: "1:105485068586:web:b9dea83c9e52e6a722d653",
		storageBucket: "apsc-46801.appspot.com",
		locationId: "us-central",
		apiKey: "AIzaSyCQoJuJkaMJ1TAKZw03Y0bQvrSHtQILbqM",
		authDomain: "apsc-46801.firebaseapp.com",
		messagingSenderId: "105485068586",
	},
	production: false,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
